@charset "utf-8";
@mixin max-screen($break-point) {
  @media screen and (max-width: $break-point) {
    @content;
  }
}

@mixin min-screen($break-point) {
  @media screen and (min-width: $break-point) {
    @content;
  }
}

@mixin screen($break-point-min, $break-point-max) {
  @media print, screen and (min-width: $break-point-min) and (max-width: $break-point-max) {
    @content;
  }
}

@mixin cf{content:"";
    display:block;
    clear:both;}

@mixin vertical-align {
position: relative;
top: 50%;
transform: translateY(-50%);
}

@mixin align {
position: relative;
left: 50%;
transform: translateX(-50%);
}

@mixin abFull{
    position: absolute;
    top:0;
    bottom:0;
    left: 0;
    right: 0;
}

@mixin iconSetting{    
  font-family: "font" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


//	作った三角形を$dirに合わせてボックスの外側にセットする
$size: '';
@mixin triangle-set($num: 10px,$color: black,$dir: top,$position: 50%,$selecter: after) {
// $num: 大きさ（10px 20px のように2つ値を書くことで横と高さを指定することができます。）
// $color: 色
// $dir: 向き（top,right,bottom,left）
// $position: 三角形をボックスのどの位置にセットするか（真ん中の場合50%）
// $selecter: after or before どちらを使うか
	$size: (nth($num,1) / 2) !global;
	$h: '';
	@if length($num) == 1 {
		$h: $num;
	} @else {
		$h: nth($num,2);
	}
	$direction: if($dir == top,bottom,if($dir == bottom,top,if($dir == left,right,if($dir == right,left,''))));
	&#{':' + $selecter} {
		@include triangle($num,$color,$dir);
		#{$dir}: -($size + $h);
		position: absolute;
		@if($position != false) {
			#{if($dir == left or $dir == right,top,left)}: $position;
			@if($position == 50%) {
				margin-#{if($dir == left or $dir == right,top,left)}: -$size;
			}
		}
	}
}


//	三角形を作る
@mixin triangle($num: 10px,$color: black,$dir: top) {
// $num: 大きさ（10px 20px のように2つ値を書くことで横と高さを指定することができます。）
// $color: 色
// $dir: 向き（top,right,bottom,left）
	$size: (nth($num,1) / 2) !global;
	$h: '';
	@if length($num) == 1 {
		$h: $num;
	} @else {
		$h: nth($num,2);
	}
	$direction: if($dir == top,bottom,if($dir == bottom,top,if($dir == left,right,if($dir == right,left,''))));
	content: "";
	font-size: 0;
	border: $size solid transparent;
	border-#{$direction}-color: $color;
	border-#{$direction}-width: $h;
}
//帯付きボックス
@mixin border-box($color:$main-color,$padding1:20px, $padding2:30px){
	border:3px solid $color;
	border-top: none;
	background: #fff;
	padding: ($padding1/2) ($padding2/2);
	& h4 {
			background: $color;
			color: #fff;
			text-align: center;
			font-weight: 700;
			font-size: 1.8rem;
			padding: 0.1em;
			margin: ($padding1/2*-1) ($padding2/2*-1);
			margin-bottom: ($padding1/2);
	}
	@include min-screen($breakpoint-pc) {
			border: 5px solid $color;
			border-top: none;
			padding: $padding1 $padding2;
			& h4 {
					font-size: 3.0rem;
					margin: ($padding1*-1) ($padding2*-1);
					margin-bottom: $padding1;
			}
	}
	@include max-screen($breakpoint-iphone5) {
//       transform: scale(0.9);
//        transform-origin: top left;
//        width: calc(100% / 0.9);
	}
}

//電話番号使いまわし
@mixin telno($color: $main-color,$size:3rem){
    display: flex;
    align-items: center;
    justify-content: center;
    color: $color;
    font-weight: 500;
    font-size: $size;    
    line-height: 1.3;
    & .txt-tellink a{
        text-decoration: none;
        color: $color;
        font-weight: 500;
    }
}

//ラベル
@mixin label{
    text-align: center;
    padding:5px 10px;
    margin:0 5px 0 0;
    font-size: 1.0rem;
    line-height: 1.2;
    background: $main-color;
    color:#fff;
    width: 50px;
    display: block;    
    @include min-screen($breakpoint-pc) {
    font-size: 1.4rem;
    min-width: 70px;
    width: auto;     
    padding: 5px 10px;
    display: table;
    margin: 0 auto;
    transition: background $speed,color $speed,;    
    }    
}

//アニメーション
@mixin anime-ponpon-scale {
    animation: anime-ponpon-scale 0.8s ease-in-out 1;
}
@mixin anime-ponpon {
    transform: translateZ(0);
    backface-visibility: hidden;

    &:hover,
    &:focus,
    &:active {
        animation: anime-ponpon 0.85s ease-in-out 1;
        //animation: anime-ponpon 1s ease-in-out 1;
    }
}
@mixin anime-hang {
animation: anime-hang 0.8s ease-in-out 1;
}



//ボタン
@mixin btn($bg:$main-color,$hover:$main-hover,$direction:"right"){
    position: relative;
    display:block;
    background: $bg;
    color: #fff;
    font-size: 1.8rem;
    font-weight: 500;
    text-align: center;
    max-width: 75vmin;
    margin: auto;
    padding: 0.7em 20px;
    //overflow: hidden;
    //border-radius: 50px;
    &:before{
        position: absolute;
        top:50%;
        #{$direction}:10px;
        transform: translateY(-50%);
    }
    @include min-screen($breakpoint-pc) {
    cursor:pointer;
    display: table;        
    min-width: 300px;
    font-size:2rem;
    padding: 0.7em 20px;
    transition: background $speed, color $speed, border $speed; 
    &:before{#{$direction}:20px;}    
    &:hover{background: $hover;}        
    }
}

//下線の見出しに使う
@mixin underline{
    border-bottom: solid 2px $main-color;
    position: relative;        
    &:after{
    content: "";
    display: block;
    position: absolute;
    right: 0;
    bottom: -2px;
    width: 50%;
    height: 2px;
    background:$underline-light;
    z-index: 2;
    } 
}

//マーカーで引いたような下線
@mixin maker($maker-color:$main-color){background:  linear-gradient(transparent 60%, $maker-color 0)}

//メニューhover
@mixin main-menu-hover{
margin:0 10px;   
content: "";
opacity: 1;
bottom: -3px;
background: $main-color;
width: 0;
height: 4px;
display: block;
position: absolute;}

//「・」でつくるリスト
@mixin CircleList{
    list-style-type: none;   
    & li{
        padding-left: 1.3em;
        position: relative;
        &:before{
            content: "・";
            position: absolute;
            left: 0;
        }
    }
}

//（1）から増えるリスト
@mixin NumberList($section:numberlist){
    list-style-type: none;
    counter-reset:$section;  
    & li{
        padding-left: 1.5em;
        counter-increment: $section;
        position: relative;
        &:before{        
        //content:"（"counter($section)"）";
        content:counter($section)".";    
        position: absolute;    
        left: 0;    
        }    
    }
}


